import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { isInMobileBrowser } from "./libs/browser";

import Navbar from "./layout/Navbar";
import Game from "./layout/Game";
import Footer from "./layout/Footer";
import PopUp from "./components/PopUp/PopUp";
import Hero from "./components/Hero/Hero";

import ScrollToTop from "./components/ScrollToTop";
import "./styles.css";

import Loader from "./components/Loader/Loader";
import bgImage from "./assets/field.png";
import Privacy from "./components/Privacy/Privacy";
import Ad from "./layout/Ad";

export default function App() {
  const location = useLocation();
  const [showOpenApp, setShowOpenApp] = useState(false);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/privacy-policy") {
      document.body.style.backgroundImage = `url(${bgImage})`;
      document.body.style.setProperty("--overlay-opacity", 0); // was 0.85
    } else {
      document.body.style.backgroundImage = "";
      document.body.style.setProperty("--overlay-opacity", 0.81);
    }
  }, [location]);

  useEffect(() => {
    let tmptLoader = document.getElementById("temp-loader");
    if (tmptLoader) {
      tmptLoader.parentNode.removeChild(tmptLoader);
    }
  }, []);

  useEffect(() => {
    setShowOpenApp(isInMobileBrowser);

    window.addEventListener("resize", (e) => {
      setShowOpenApp(isInMobileBrowser);
    });

    let tmptLoader = document.getElementById("temp-loader");
    if (tmptLoader) {
      tmptLoader.parentNode.removeChild(tmptLoader);
    }

    return () => {
      window.removeEventListener("resize", (e) => {
        setShowOpenApp(isInMobileBrowser);
      });
    };
  }, []);

  return (
    <div id="app">
      <Navbar />
      <Loader />
      <ScrollToTop />
      <Routes>
        <Route path="/game">
          <Route
            path=":gameName"
            element={
              <>
                <Game />
              </>
            }
          />
        </Route>
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route
          path="/"
          element={
            <>
              <Game />
              <Hero />
              <div className="relative w-full">
                <div
                  className="vm-placement"
                  data-id="642675f41e85fb3356e14cf8"
                  style={{ display: "none" }}
                ></div>
              </div>
            </>
          }
        />
      </Routes>
      <Footer />
      <div className="black-glass"></div>
      <PopUp mobileDeviceInfo={showOpenApp} />
    </div>
  );
}
